import React from 'react';
import parse from 'html-react-parser';
//import { Container } from 'reactstrap';
import { Link} from 'gatsby';
import WithUIKit from '../components/Custom/WithUIKit';

import FluidImage from '../components/FluidImage';
import DefaultLayout from '../layouts/default';
import Breadcrumbs from '../components/Custom/Navigation/Breadcrumbs';


const Reference = ({ pageContext, location }) => {
  console.log('#################', pageContext.website);

  const getContent = () => {
    return parse(pageContext.html, {
      replace: domNode => {
        
        if (domNode.name === 'img') {
          //console.log("getContent", domNode.attribs);
          return (
            <>
                <FluidImage
                src={domNode.attribs.src}
                className=""
                />
            </>
          );
        }
      },
    });
  };


  return (
    <DefaultLayout location={location}>

      <div className="hero hero-cover is-max-fullheight">
        
          <div className="hero-bg">
            <FluidImage
              src={"/static/images/" + pageContext.image}
              className="image"
              alt={pageContext.caption}
              />
          </div>

          <div className="hero-body">
              <div className="container is-layout">
                  <section className="section">
                      <h1 className="title">{pageContext.caption}</h1>
                      <h2 className="subtitle">{pageContext.subtitle}</h2>
                  </section>
              </div>
          </div>

      </div>

      <Breadcrumbs location={location} />

      <div className="container is-layout">
          
          <section className="section">
            <div className="columns is-multiline is-mobile">
              {pageContext.client && <div className="column is-half-mobile">
                    <p className="subtitle">Client</p>
                    <p className="title">{pageContext.client || ""}</p>
                </div>}
                {pageContext.caption && <div className="column is-half-mobile">
                    <p className="subtitle">Project</p>
                    <p className="title">{pageContext.caption || ""}</p>
                </div>}
                {pageContext.topic && <div className="column is-half-mobile">
                    <p className="subtitle">Topic</p>
                    <p className="title">{pageContext.topic || ""}</p>
                </div>}
                {pageContext.website && <div className="column is-half-mobile">
                    <p className="subtitle">Website</p>
                    <p className="title">{pageContext.website && <a href={pageContext.website}>{pageContext.website.replace("https://", "").replace("http://", "")}</a>}</p>
                </div>}
            </div>
          </section>

      </div>


      <div className="container is-layout">
          
        {getContent()}

      </div>
      { pageContext.next && 
        <div className="container is-fullwidth">
            <section className="section">
                <nav className="breadcrumb breadcrumb-forward is-small is-centered is-letter-spacing is-uppercase">
                    <ul>
                        <li>
                          <Link to={pageContext.next.slug}>Next Project: {pageContext.next.caption}<span className="icon icon-arrow-right"></span></Link></li>
                    </ul>
                </nav>
            </section>
        </div>
      }
      


    </DefaultLayout>
  );
};

export default WithUIKit(Reference);
